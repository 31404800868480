<template>
    <nav id="sidenav" :class="['sidenav', { open: menu.show }]" :style="{ visibility: visibility }">
        <main-nav :menu.sync="menu">
            <li
                v-if="isSuperAdmin"
                class="item admin"
                :class="{'active': menu.active === 'admin'}"
                @click="openMenu('admin')"
            >
                <a href="/admin" class="item-link" @click.prevent>
                    <i class="icon fal fa-users-crown" />
                    <p class="name">{{ trans("messages.Admin") }}</p>
                </a>
            </li>
            <li class="item" :class="{ active: menu.active === 'projects' }" @click="openMenu('projects')">
                <a href="/prosjekter" class="item-link" @click.prevent>
                    <svg-icon height="5em" width="5em" name="nodes" />
                    <p class="name">{{ trans("shared.Prosjekter") }}</p>
                </a>
            </li>
            <li
                v-if="hasModuleHr && hasModuleTimereg"
                class="item"
                :class="{ active: menu.active === 'hours' }"
                @click="openMenu('hours')"
            >
                <a href="/timereg/registrering" class="item-link" @click.prevent>
                    <svg-icon
                        height="4.5em"
                        width="4.5em"
                        style="margin-left: 10px;"
                        name="module-timereg"
                    />
                    <p class="name">{{ trans("shared.Timer") }}</p>
                </a>
            </li>
            <li class="item" :class="{'active': menu.active === 'hr'}" @click="openMenu('hr')">
                <a href="/personal/ansatte" class="item-link" @click.prevent>
                    <svg-icon height="5em" width="5em" name="people" />
                    <p class="name">{{ trans("shared.Personal") }}</p>
                </a>
            </li>
            <li
                v-if="isAdmin"
                class="item"
                :class="{'active': menu.active === 'deviations'}"
                @click="openMenu('deviations')"
            >
                <a href="/avvik/oversikt" class="item-link" @click.prevent>
                    <svg-icon height="5em" width="5em" name="warning" />
                    <p class="name">{{ trans("shared.Avvik") }}</p>
                </a>
            </li>
            <li class="item" :class="{'active': menu.active === 'company'}" @click="openMenu('company')">
                <a href="/bedrift" class="item-link" @click.prevent>
                    <svg-icon height="5em" width="5em" name="building" />
                    <p class="name">{{ trans("contacts.Bedrift") }}</p>
                </a>
            </li>
            <li
                v-if="isAdmin"
                class="item"
                :class="{'active': menu.active === 'templates'}"
                @click="openMenu('templates')"
            >
                <a href="/maler/sjekklister" class="item-link" @click.prevent>
                    <svg-icon height="5em" width="5em" name="list" />
                    <p class="name">{{ trans("shared.Maler") }}</p>
                </a>
            </li>
            <li class="item" :class="{'active': menu.active === 'contacts'}">
                <a href="/kontakter" class="item-link">
                    <svg-icon height="5em" width="5em" name="addressbook" />
                    <p class="name">{{ trans("shared.Kontakter") }}</p>
                </a>
            </li>
            <li
                v-if="canSeeOrders"
                class="item"
                :class="{'active': menu.active === 'orders'}"
                @click="openMenu('orders')"
            >
                <a href="/ordrer" class="item-link" @click.prevent>
                    <svg-icon height="5em" width="5em" name="orders" />
                    <p class="name">{{ trans("shared.Ordrer") }}</p>
                </a>
            </li>
        </main-nav>

        <side-menu :menu.sync="menu">
            <!-- *******   Prosjekter   ******* -->
            <template #links>
                <div v-show="menu.parent === 'projects'" data-parent="projects">
                    <section>
                        <i class="icon fal fa-project-diagram" />
                        <ul>
                            <li>
                                <a href="/prosjekter" data-alt-hrefs="/prosjekter/rediger, /prosjekter/ny">
                                    {{ trans("shared.Prosjekter") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-newspaper" />
                        <ul>
                            <li>
                                <a href="/prosjekt">{{ trans("shared.Prosjektforside") }}</a>
                            </li>
                            <li>
                                <a href="/prosjekt/meldinger">{{ trans("project-messages.Prosjektmeldinger") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-book" />
                        <ul>
                            <li>
                                <a href="/prosjekt/prosedyrer" data-alt-hrefs="/prosjekt/prosedyrer">
                                    {{ trans("procedure-project.Prosedyrer") }}
                                </a>
                            </li>
                            <li v-if="isProjectManager">
                                <a href="/prosjekt/prosedyrer/tildeling" data-alt-hrefs="/prosjekt/prosedyrer">
                                    {{ trans("procedure-project.Prosedyretildeling") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-clipboard-list-check" />
                        <ul>
                            <li>
                                <a
                                    href="/prosjekt/sjekklister"
                                    data-alt-hrefs="/prosjekt/sjekkliste, /prosjekt/sjekklister/leggtil"
                                >
                                    {{ trans("shared.Sjekklister") }}
                                </a>
                            </li>
                            <li v-if="isProjectManager">
                                <a href="/prosjekt/sjekklister/kategorier">{{ trans("shared.Kategorier") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-exclamation-triangle" />
                        <ul>
                            <li>
                                <a href="/prosjekt/avvik">{{ trans("mobile.Avvik / RUH") }}</a>
                            </li>
                            <li v-if="isAdmin">
                                <a href="/avvik/oversikt">{{ trans("shared.Oversikt") }}</a>
                            </li>
                            <li>
                                <a href="/prosjekt/avvik">{{ trans("deviation-list.Avviksliste") }}</a>
                            </li>
                            <li v-if="isProjectManager">
                                <a href="/prosjekt/avvik/instillinger">
                                    {{ trans("shared.Innstillinger") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-images" />
                        <ul>
                            <li>
                                <a href="/prosjektbilder" data-alt-hrefs="/prosjektbilde">
                                    {{ trans("shared.Bilder") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-file-download" />
                        <ul>
                            <li>
                                <a href="/prosjekt/filer">{{ trans("shared.Filer") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-file-invoice" />
                        <ul>
                            <li>
                                <a href="/prosjekt/spesialskjemaer" data-alt-hrefs="/prosjekt/spesialskjemaer">
                                    {{ trans("shared.Skjema") }}
                                </a>
                            </li>
                            <li>
                                <a href="/prosjekt/skjemaer" data-alt-hrefs="/prosjekt/skjemaer">
                                    {{ trans("forms.Egendefinerte skjema") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="canAccessDesktopInspection">
                        <i class="icon fal fa-clipboard-list-check" />
                        <ul>
                            <li>
                                <a href="/prosjekt/befaring">{{ trans("inspection.Befaringer") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager">
                        <i class="icon fal fa-file-signature" />
                        <ul>
                            <li>
                                <a href="/prosjekt/soeknader" data-alt-hrefs="/prosjekt/soeknad">
                                    {{ trans("shared.Søknader") }}
                                </a>
                            </li>
                            <li v-if="hasModuleNorkart">
                                <a href="/ebyggsok-soeknader">eByggesøk Proff</a>
                            </li>
                            <li>
                                <a href="/prosjekt/aktorer">{{ trans("shared.Entreprenører") }}</a>
                            </li>
                            <li v-if="hasAnssokCurrentProject">
                                <a href="/prosjekt/naboer">{{ trans("shared.Naboer") }}</a>
                            </li>
                            <li>
                                <a href="/soeknader">{{ trans("shared.PDF-blanketter") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="hasModuleHr && hasModuleTimereg">
                        <i class="icon fal fa-clock" />
                        <ul>
                            <li>
                                <a :href="`/timereg/liste/${pid}`">{{ trans("shared.Timer") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-folder-plus" />
                        <ul>
                            <li>
                                <a href="/prosjekt/perm">{{ trans("shared.Prosjektperm") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager && hasAnssokCurrentProject">
                        <i class="icon fal fa-book" />
                        <ul>
                            <li>
                                <a href="/prosjekt/fdv/perm">{{ trans("shared.FDV-perm") }}</a>
                            </li>
                            <li>
                                <a href="/prosjekt/fdv/rom">{{ trans("shared.Innredningsdata") }}</a>
                            </li>
                            <li>
                                <a href="/prosjekt/fdv/aktorer">{{ trans("shared.Entreprenører") }}</a>
                            </li>
                            <li>
                                <a href="/prosjekt/fdv/levmat">{{ trans("shared.Leverandører materiell") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager && hasShaCurrentProject">
                        <i class="icon fal fa-folder-minus" />
                        <ul>
                            <li>
                                <a href="/prosjekt/sha/perm">{{ trans("shared.SHA-perm") }}</a>
                            </li>
                            <li>
                                <a href="/prosjekt/organisasjon">{{ trans("shared.Prosjektorganisasjon") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-network-wired" />
                        <ul>
                            <li>
                                <a href="/prosjekt/orgchart">{{ trans("shared.Organisasjonskart") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-copy" />
                        <ul>
                            <li>
                                <a href="/prosjekt/dokumenter">{{ trans("shared.Dokumenter") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="hasModuleInfotech">
                        <i class="icon fal fa-user-check" />
                        <ul>
                            <li>
                                <a href="/infotech">{{ trans("shared.Mannskapsliste") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager">
                        <i class="icon fal fa-file-contract" />
                        <ul>
                            <li>
                                <a href="/eblanketter">{{ trans("eblanketter.NS-Kontrakter") }}</a>
                            </li>
                            <li>
                                <a href="/prosjekt/ns-kontrakter">{{ trans("shared.Gammelt system") }}</a>
                            </li>
                        </ul>
                    </section>
                </div>

                <!-- *******     Timer      ******* -->
                <div v-if="hasModuleHr && hasModuleTimereg" v-show="menu.parent === 'hours'" data-parent="hours">
                    <section class="open">
                        <i class="icon fal fa-clock" />
                        <ul>
                            <li>
                                <a href="/timereg/registrering">{{ trans("mobile.Timeregistrering") }}</a>
                            </li>
                        </ul>
                    </section>
                    <section>
                        <i class="icon fal fa-calendar-week" />
                        <ul>
                            <li>
                                <a href="/timereg/liste">{{ trans("shared.Timeliste") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="canAccessDesktopProjectNode">
                        <i class="icon fal fa-business-time" />
                        <ul>
                            <li>
                                <a href="/prosjekt/timebudsjett">{{ trans("hour-budget.Timebudsjett") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager || isTimeAdmin">
                        <i class="icon fal fa-calendar-check" />
                        <ul>
                            <li>
                                <a href="/prosjekt/ukekontroll">{{ trans("weekly-revisions.Ukekontroll") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="! hasDisabledHourbank">
                        <fa-icon :icon="['fal', 'piggy-bank']" class="icon" />
                        <ul>
                            <li v-if="isProjectManager || isTimeAdmin">
                                <a href="/hourbank">{{ trans("timereg-hourbank.Timebank") }}</a>
                            </li>
                            <li v-else>
                                <a href="/hourbank">{{ trans("timereg-hourbank.Min timebank") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isTimeAdmin">
                        <i class="icon fal fa-file-export" />
                        <ul>
                            <li>
                                <a href="/timereg/eksport/lonn" data-alt-hrefs="/timereg/eksport/lonn/ny">
                                    {{ trans("salary-export.Lønnseksport") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isTimeAdmin">
                        <i class="icon fal fa-file-chart-line" />
                        <ul>
                            <li>
                                <a href="/timereg/rapporter">{{ trans("timereg-list.Rapporter") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="hasModuleAbax">
                        <i class="icon fal fa-car" />
                        <ul>
                            <li>
                                <a href="/timereg/abax">{{ trans("timereg-list.Abax kjørebok") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isTimeAdmin">
                        <i class="icon fal fa-sliders-h" />
                        <ul>
                            <li>
                                <a href="/timereg/innstillinger">{{ trans("shared.Innstillinger") }}</a>
                            </li>
                            <li>
                                <a href="/timereg/maskiner">{{ trans("timereg-list.Maskintyper") }}</a>
                            </li>
                            <li>
                                <a href="/timereg/tilleggstyper">{{ trans("timereg-list.Tilleggstyper") }}</a>
                            </li>
                            <li>
                                <a href="/timereg/timetyper">{{ trans("timereg-list.Timetyper") }}</a>
                            </li>
                            <li>
                                <a href="/timereg/overtidstyper">{{ trans("timereg-list.Overtidstyper") }}</a>
                            </li>
                            <li>
                                <a href="/timereg/avdeling">{{ trans("timereg-list.Avdelinger") }}</a>
                            </li>
                        </ul>
                    </section>
                </div>

                <!-- *******    Personal    ******* -->
                <div v-show="menu.parent === 'hr'" data-parent="hr">
                    <section v-if="hasModuleHr && isHrAdmin">
                        <i class="icon fal fa-users" />
                        <ul>
                            <li>
                                <a href="/personal/ansatte" data-alt-hrefs="/personal/ansatt">
                                    {{ trans("shared.Oversikt ansatte") }}
                                </a>
                            </li>
                            <li>
                                <a href="/personal/avdelinger" data-alt-hrefs="/personal/avdelinger">
                                    {{ trans("shared.Innstillinger") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-id-card" />
                        <ul>
                            <li>
                                <a href="/personal/profil">{{ trans("hr.Min profil") }}</a>
                            </li>
                        </ul>
                    </section>

                    <template v-if="hasModuleHr">
                        <section>
                            <i class="icon fal fa-file-user" />
                            <ul>
                                <li>
                                    <a
                                        :href="`/personal/cv/${userId}`"
                                        data-alt-hrefs="/personal/cv"
                                    >
                                        {{ trans("shared.CV") }}
                                    </a>
                                </li>
                                <li v-if="isHrAdmin">
                                    <a href="/personal/cv-innstillinger">{{ trans("shared.CV innstillinger") }}</a>
                                </li>
                                <li v-if="isHrAdmin">
                                    <a href="/personal/cv-notifications">
                                        {{ trans('hr-cv.CV-varsling') }}
                                    </a>
                                </li>
                            </ul>
                        </section>

                        <section v-if="hasModuleHrShowFolder">
                            <i class="icon fal fa-book" />
                            <ul>
                                <li>
                                    <a href="/personal/perm">{{ trans("shared.Personalhåndbok") }}</a>
                                </li>
                            </ul>
                        </section>

                        <template v-if="isHrAdmin">
                            <section>
                                <i class="icon fal fa-user-tie" />
                                <ul>
                                    <li>
                                        <a href="/personal/kompetanseoversikt">
                                            {{ trans("shared.Kompetanseoversikt") }}
                                        </a>
                                    </li>
                                </ul>
                            </section>
                            <section>
                                <i class="icon fal fa-comments" />
                                <ul>
                                    <li>
                                        <a href="/personal/medarbeidersamtale">
                                            {{ trans("shared.Medarbeidersamtale") }}
                                        </a>
                                    </li>
                                </ul>
                            </section>
                            <section>
                                <i class="icon fal fa-file-certificate" />
                                <ul>
                                    <li>
                                        <a href="/personal/ansettelsesavtaler">
                                            {{ trans("shared.Ansettelsesavtaler") }}
                                        </a>
                                    </li>
                                </ul>
                            </section>
                        </template>
                        <section v-if="hasModuleAbsence && isHrAdmin">
                            <i class="icon fal fa-thermometer" />
                            <ul>
                                <li>
                                    <a href="/personal/fravaer">{{ trans('shared.Fravær') }}</a>
                                </li>
                                <li>
                                    <a href="/personal/fravaer-timeline">{{ trans('shared.Tidslinje') }}</a>
                                </li>
                                <li>
                                    <a href="/personal/hr-notifications">{{ trans('absence.HR-varsling') }}</a>
                                </li>
                            </ul>
                        </section>
                    </template>
                    <template v-else>
                        <section>
                            <i class="icon fal fa-users" />
                            <ul>
                                <li>
                                    <a href="/personal">{{ trans("shared.Informasjon om personal") }}</a>
                                </li>
                            </ul>
                        </section>
                    </template>
                </div>

                <!-- ******* Avviksregister ******* -->
                <div v-show="menu.parent === 'deviations'" class="deviations" data-parent="deviations">
                    <affix relative-element-selector=".deviations" :offset="{ top: 10, bottom: 0 }">
                        <section class="open">
                            <i class="icon fal fa-file-signature" />
                            <ul>
                                <li>
                                    <a href="/avvik/oversikt">{{ trans("shared.Avvik") }}</a>
                                </li>
                                <template v-if="isAdmin">
                                    <li>
                                        <a href="/avvik/oversikt">{{ trans("shared.Oversikt") }}</a>
                                    </li>
                                    <li>
                                        <a href="/prosjekt/avvik">{{ trans("deviation-list.Avviksliste") }}</a>
                                    </li>
                                    <li>
                                        <a href="/avvik/instillinger">{{ trans("shared.Innstillinger") }}</a>
                                    </li>
                                    <li>
                                        <a href="/avvik/utstyr">{{ trans("deviation.Utstyr") }}</a>
                                    </li>
                                    <li>
                                        <a href="/avvik/prosesser">{{ trans("deviation.Prosess") }}</a>
                                    </li>
                                    <li>
                                        <a href="/avvik/prosedyrer">{{ trans("deviation.Prosedyre / Regelverk") }}</a>
                                    </li>
                                    <li>
                                        <a href="/avvik/kategorier">{{ trans("shared.Kategorier") }}</a>
                                    </li>
                                </template>
                            </ul>
                        </section>
                    </affix>
                </div>

                <!-- *******    Bedrift     ******* -->
                <div v-show="menu.parent === 'company'" data-parent="company">
                    <section>
                        <i class="icon fal fa-clipboard-list" />
                        <ul>
                            <li><a href="/bedrift/hmsplan">{{ trans("shared.HMS-plan") }}</a></li>
                            <li><a href="/bedrift/hmsplan/filer">{{ trans("shared.Dokumenter") }}</a></li>
                        </ul>
                    </section>

                    <section v-if="hasModuleProceduresDesktop && !canOnlyViewProjectProcedures">
                        <i class="icon fal fa-book" />
                        <ul>
                            <li>
                                <a href="/bedrift/prosedyrer" data-alt-hrefs="/bedrift/prosedyre">
                                    {{ trans("shared.Prosedyrer") }}
                                </a>
                            </li>
                            <li v-if="isAdmin">
                                <a href="/bedrift/lesebekreftelser">{{ trans("shared.Lesebekreftelser") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="canSeeCompanyFiles">
                        <i class="icon fal fa-folders" />
                        <ul>
                            <li><a href="/bedrift/filer">{{ trans("shared.Filer") }}</a></li>
                        </ul>
                    </section>

                    <section v-if="isAdmin">
                        <i class="icon fal fa-cogs" />
                        <ul>
                            <li>
                                <a href="/bedrift/innstillinger">{{ trans("shared.Innstillinger") }}</a>
                            </li>
                            <li v-if="hasModuleHr">
                                <a href="/bedrift/innstillinger/ordre-v2">{{ trans("shared.Ordre") }}</a>
                            </li>
                            <li v-if="hasModuleHr">
                                <a href="/bedrift/innstillinger/ordre">
                                    {{ trans("shared.Ordre") }} ({{ trans("shared.Gammel") }})
                                </a>
                            </li>
                            <li>
                                <a href="/bedrift/innstillinger/topplogo">{{ trans("shared.Topplogo") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/innstillinger/befaring">{{ trans("inspection.Befaring") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/innstillinger/timebudsjett">
                                    {{ trans("hour-budget.Timebudsjett") }}
                                </a>
                            </li>
                            <li>
                                <a href="/bedrift/innstillinger/sms">{{ trans("2fa.SMS") }}</a>
                            </li>
                            <li v-if="hasModuleProcedures">
                                <a href="/bedrift/innstillinger/prosedyre">{{ trans("shared.Prosedyre") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/innstillinger/ns-kontrakter">{{ trans("shared.NS-kontrakter") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/innstillinger/norkart">eByggesøk Proff</a>
                            </li>
                            <li>
                                <a href="/bedrift/innstillinger/abax">{{ trans("timereg-list.Abax kjørebok") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isAdmin">
                        <i class="icon fal fa-chart-network" />
                        <ul>
                            <li><a href="/bedrift/integrasjoner">{{ trans("shared.Integrasjoner") }}</a></li>
                            <li><a href="/bedrift/integrasjoner/infotech">Infotech</a></li>
                            <li><a href="/bedrift/integrasjoner/tripletex">Tripletex</a></li>
                            <li><a href="/bedrift/integrasjoner/cordel">Cordel</a></li>
                            <li><a href="/bedrift/integrasjoner/boligmappa">Boligmappa</a></li>
                            <li>
                                <a href="/bedrift/integrasjoner/integration-platform">
                                    {{ trans("shared.Integrasjonsplattform") }}
                                </a>
                            </li>
                            <li><a href="/bedrift/innstillinger/norkart">eByggesøk Proff</a></li>
                            <li>
                                <a href="/bedrift/innstillinger/abax">{{ trans("timereg-list.Abax kjørebok") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-building" />
                        <ul>
                            <li>
                                <a href="/bedrift">{{ trans("shared.Min bedrift") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/kontaktpersoner">{{ trans("shared.Kontaktpersoner") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/organisasjonskart">{{ trans("shared.Organisasjonskart") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/ansvarsfordeling">{{ trans("shared.Ansvarsfordeling") }}</a>
                            </li>
                            <li v-if="hasModuleHr">
                                <a href="/bedrift/oversiktansatte">{{ trans("shared.Oversikt ansatte") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/hmsmaal">{{ trans("shared.HMS-mål") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/vernetjenesten">
                                    {{ trans("safety-representatives.Vernetjenesten") }}
                                </a>
                            </li>
                            <li>
                                <a href="/bedrift/bedriftshelsetjenesten">
                                    {{ trans("shared.Bedriftshelsetjenesten") }}
                                </a>
                            </li>
                            <li>
                                <a href="/bedrift/arbeidsmiljoutvalg">{{ trans("shared.Arbeidsmiljøutvalg") }}</a>
                            </li>
                            <li>
                                <a href="/bedrift/lover">{{ trans("shared.Lover og forskrifter") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-link" />
                        <ul>
                            <li>
                                <a href="/bedrift/lenker">{{ trans("shared.Lenker") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isAdmin && hasModuleSms">
                        <i class="icon fal fa-sms" />
                        <ul>
                            <li>
                                <a href="/bedrift/sms">
                                    {{ trans("sms-administration.SMS-forbruk") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-envelope" />
                        <ul>
                            <li>
                                <a href="/bedrift/email-log">
                                    {{ trans("email-log.E-post logg") }}
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>

                <!-- *******     Maler     ******* -->
                <div v-if="isAdmin" v-show="menu.parent === 'templates'" data-parent="templates">
                    <section>
                        <i class="icon fal fa-clipboard-list-check" />
                        <ul>
                            <li>
                                <a href="/maler/sjekklister" data-alt-hrefs="/maler/sjekkliste">
                                    {{ trans("shared.Sjekklister") }}
                                </a>
                            </li>
                            <li>
                                <a href="/maler/sjekklister/kategorier">{{ trans("shared.Kategorier") }}</a>
                            </li>
                            <li>
                                <a href="/maler/prosjektmaler" data-alt-hrefs="/maler/prosjektmal">
                                    {{ trans("shared.Prosjektmaler") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-copy" />
                        <ul>
                            <li>
                                <a href="/dokumenter" data-alt-hrefs="/dokument">{{ trans("shared.Dokumentmaler") }}</a>
                            </li>
                            <li>
                                <a href="/dokumenter" data-alt-hrefs="/dokument">{{ trans("shared.Dokumenter") }}</a>
                            </li>
                            <li v-if="hasModuleHr">
                                <a href="/dokumenter/personal">{{ trans("shared.Personal") }}</a>
                            </li>
                            <li>
                                <a href="/dokumenter/hmsplan">{{ trans("shared.HMS-plan") }}</a>
                            </li>
                            <li v-if="hasAnssokProjects">
                                <a href="/dokumenter/fdv">FDV</a>
                            </li>
                            <li v-if="hasShaProjects">
                                <a href="/dokumenter/sha">SHA</a>
                            </li>
                            <li>
                                <a href="/dokumenter/andre">{{ trans("shared.Andre") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-folder-open" />
                        <ul>
                            <li>
                                <a href="/perm/prosjekt">{{ trans("shared.Perm-maler") }}</a>
                            </li>
                            <li>
                                <a href="/perm/prosjekt">{{ trans("shared.Prosjektperm") }}</a>
                            </li>
                            <li v-if="hasModuleHr && hasModuleHrShowFolder">
                                <a href="/perm/personal">{{ trans("shared.Personalhåndbok") }}</a>
                            </li>
                            <li v-if="hasAnssokProjects">
                                <a href="/perm/fdv">{{ trans("shared.FDV-perm") }}</a>
                            </li>
                            <li v-if="hasShaProjects">
                                <a href="/perm/sha">{{ trans("shared.SHA-perm") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager">
                        <i class="icon fal fa-file-invoice" />
                        <ul>
                            <li>
                                <a href="/maler/skjema" data-alt-hrefs="/preview/maler/skjema">{{ trans("shared.Skjema") }}</a>
                            </li>
                            <li>
                                <a href="/maler/skjema/tags" data-alt-hrefs="/maler/skjema/tags">
                                    {{ trans("forms.Tags liste") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager">
                        <i class="icon fal fa-folders" />
                        <ul>
                            <li>
                                <a href="/maler/prosjektfilmapper">{{ trans("shared.Prosjektfil-mapper") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager">
                        <i class="icon fal fa-ballot-check" />
                        <ul>
                            <li>
                                <a href="/maler/befaringssjekklister" data-alt-hrefs="/maler/befaringssjekkliste">
                                    {{ trans("inspection.Befaringssjekklister") }}
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>

                <!-- *******      Admin     ******* -->
                <div v-show="menu.parent === 'admin'" data-parent="admin">
                    <section>
                        <i class="icon fal fa-mask" />
                        <ul>
                            <li>
                                <a href="/admin" data-alt-hrefs="/admin">{{ trans("shared.Adminpanel") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-suitcase" />
                        <ul>
                            <li>
                                <a href="/admin/bransjer">{{ trans("messages.Bransjer") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-file-invoice" />
                        <ul>
                            <li>
                                <a href="/admin/bransjemaler" data-alt-hrefs="/admin/bransjemal">
                                    {{ trans("shared.Bransjemaler") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-book" />
                        <ul>
                            <li>
                                <a href="/admin/prosedyrer/0/0" data-alt-hrefs="/admin/prosedyre">
                                    {{ trans("shared.Prosedyrer") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-copy" />
                        <ul>
                            <li>{{ trans("shared.Dokumentmaler") }}</li>
                            <li>
                                <a href="/admin/dokumenter" data-alt-hrefs="/admin/dokument">
                                    {{ trans("shared.Dokument") }}
                                </a>
                            </li>
                            <li><a href="/admin/dokumenter/personal">{{ trans("shared.Personal") }}</a></li>
                            <li><a href="/admin/dokumenter/hmsplan">{{ trans("shared.HMS-plan") }}</a></li>
                            <li><a href="/admin/dokumenter/fdv">FDV</a></li>
                            <li><a href="/admin/dokumenter/sha">SHA</a></li>
                            <li><a href="/admin/dokumenter/andre">{{ trans("shared.Andre") }}</a></li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-clipboard-list-check" />
                        <ul>
                            <li><a href="/admin/sjekklister">{{ trans("shared.Sjekklister") }}</a></li>
                            <li><a href="/admin/sjekklister/kategorier">{{ trans("shared.Kategorier") }}</a></li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-file-invoice" />
                        <ul>
                            <li>
                                <a href="/admin/maler/skjema" data-alt-hrefs="/preview/admin/maler/skjema">
                                    {{ trans("shared.Egendefinerte skjemamaler") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-medal" />
                        <ul>
                            <li>
                                <a href="/admin/hmsmaal/1">{{ trans("shared.HMS-mål") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-folder" />
                        <ul>
                            <li>{{ trans("shared.Perm-maler") }}</li>
                            <li><a href="/admin/perm/personal">{{ trans("shared.Personalhåndbok") }}</a></li>
                            <li><a href="/admin/perm/prosjekt">{{ trans("shared.Prosjektperm") }}</a></li>
                            <li><a href="/admin/perm/fdv">{{ trans("shared.FDV-perm") }}</a></li>
                            <li><a href="/admin/perm/sha">{{ trans("shared.SHA-perm") }}</a></li>
                            <li><a href="/admin/perm/hms">{{ trans("shared.HMS-perm") }}</a></li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-sms" />
                        <ul>
                            <li>
                                <a href="/admin/sms/fakturaoversikt" data-alt-hrefs="/admin/sms/fakturaoversikt">
                                    {{ trans("shared.SMS-faktura") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section>
                        <i class="icon fal fa-file-signature" />
                        <ul>
                            <li>{{ trans("deviation.Avvik") }}</li>
                            <li>
                                <a href="/avvik/standardkategorier">{{ trans("shared.Standardkategorier") }}</a>
                            </li>
                        </ul>
                    </section>
                </div>

                <!-- *******     Orders     ******* -->

                <div
                    v-if="canSeeOrders"
                    v-show="menu.parent === 'orders'"
                    data-parent="orders"
                >
                    <section v-if="(hasNewOrderModule && canReadOrders) || isAdmin">
                        <i class="icon fal fa-table" />
                        <ul>
                            <li>
                                <a
                                    v-if="hasNewOrderModule"
                                    href="/ordrer"
                                    data-alt-hrefs="/ordre/ny, /ordre, /fakturagrunnlag"
                                >
                                    {{ trans("orders.Ordreregister") }}
                                </a>
                                <a v-else href="/bedrift/innstillinger/ordre-v2">
                                    {{ trans("orders.Ordreregister") }}
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="hasModuleOrder && hasModuleHr && (isProjectManager || hasModuleOrderBrukerRead)">
                        <i class="icon fal fa-person-carry" />
                        <ul>
                            <li>
                                <a href="/prosjekt/legacy-ordre-list">
                                    {{ trans("shared.Ordrer") + ' ' }}
                                    <span class="legacy-info-text">
                                        ({{ trans("shared.Gammel") }})
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager">
                        <i class="icon fal fa-boxes" />
                        <ul>
                            <li>
                                <a href="/produkter">{{ trans("orders.Produkter") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isProjectManager">
                        <i class="icon fal fa-address-book" />
                        <ul>
                            <li>
                                <a href="/kontakter">{{ trans("contacts.Leverandører") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="(hasNewOrderModule && isProjectManager) || isAdmin">
                        <i class="icon fal fa-hand-holding-usd" />
                        <ul>
                            <li>
                                <a v-if="hasNewOrderModule" href="/prislister">{{ trans("orders.Prislister") }}</a>
                                <a v-else href="/bedrift/innstillinger/ordre-v2">{{ trans("orders.Prislister") }}</a>
                            </li>
                        </ul>
                    </section>

                    <section v-if="isAdmin">
                        <i class="icon fal fa-cogs" />
                        <ul>
                            <li>
                                <a href="/ordre/innstillinger">
                                    {{ trans("shared.Innstillinger") }}
                                </a>
                            </li>
                        </ul>
                    </section>
                </div>
            </template>
        </side-menu>
    </nav>
</template>

<script>
import Vue from 'vue';
import Affix from 'vue-affix';
import { svgIcon } from 'vue-components';
import SideMenu from '@/views/side-menu/side-menu.vue';
import MainNav from '@/views/side-menu/components/main-nav.vue';
import { getFromLocalStorage, setInLocalStorage } from '../../libs/local-storage.js';
import { mapState } from 'vuex';

Vue.use(Affix);

export default {
    name: 'desktop-side-menu',

    components: {
        svgIcon,
        SideMenu,
        MainNav,
    },

    props: {
        canOnlyViewProjectProcedures: { type: Boolean },
        canAccessDesktopInspection: { type: Boolean },
        canAccessDesktopProjectNode: { type: Boolean },
        hasAnssokCurrentProject: { type: Boolean },
        hasAnssokProjects: { type: Boolean },
        hasModuleHourBudget: { type: Boolean },
        hasModuleAbsence: { type: Boolean },
        hasAnyOldSickleaves: { type: Boolean },
        hasModuleHr: { type: Boolean },
        hasModuleHrShowFolder: { type: Boolean },
        hasModuleInfotech: { type: Boolean },
        hasModuleOrder: { type: Boolean },
        hasModuleOrderBrukerRead: { type: Boolean },
        hasNewOrderModule: { type: Boolean },
        hasModuleProcedures: { type: Boolean },
        hasModuleProceduresDesktop: { type: Boolean },
        hasModuleSms: { type: Boolean },
        hasModuleNorkart: { type: Boolean },
        hasModuleAbax: { type: Boolean },
        hasModuleTimereg: { type: Boolean },
        hasShaCurrentProject: { type: Boolean },
        hasShaProjects: { type: Boolean },
        hasDisabledHourbank: { type: Boolean },
        canSeeCompanyFiles: { type: Boolean },
        isAdmin: { type: Boolean },
        isCurrentProjectManager: { type: Boolean },
        isProjectManager: { type: Boolean },
        isSuperAdmin: { type: Boolean },
        isTimeAdmin: { type: Boolean },
        isHrAdmin: { type: Boolean },
        pid: { type: Number, default: null },
        canReadOrders: { type: Boolean },
    },

    data: () => ({
        rendered: false,
        menu: {
            active: '',
            show: true,
            sticky: true,
            parent: 'projects',
        },
    }),

    computed: {
        ...mapState({
            userId: state => state.user.user.id,
        }),
        visibility() {
            return this.rendered ? 'visible' : 'hidden';
        },
        menuIsSticky() {
            return this.menu.sticky;
        },
        canSeeOrders() {
            return this.isProjectManager
                || this.canReadOrders
                || (this.hasModuleOrder && this.hasModuleHr && (this.isProjectManager || this.hasModuleOrderBrukerRead));
        },
    },

    watch: {
        'menu.sticky'(sticky) {
            this.menu.show = sticky;
            setInLocalStorage('menuIsSticky', sticky);
        },
    },

    created() {
        let storeIsSticky = getFromLocalStorage('menuIsSticky');

        if (storeIsSticky === null) {
            setInLocalStorage('menuIsSticky', true);
            storeIsSticky = true;
        }

        this.menu.sticky = storeIsSticky;

        if (window.location.pathname === '/kontakter') {
            this.menu.show = false;
        } else {
            this.menu.show = storeIsSticky;
        }
    },

    mounted() {
        this.rendered = true;

        const articleContainer = document.getElementById('articlecontainer');

        if (articleContainer) {
            articleContainer.style.visibility = 'visible';
        }
    },

    methods: {
        goto(href, hide) {
            if (hide) {
                this.menu.show = false;
            }
            window.location = href;
        },
        openMenu(type) {
            if (type === false) {
                this.menu.parent = '';
            } else {
                if (this.menu.parent === type) {
                    this.menu.show = !this.menu.show;
                } else {
                    this.menu.show = true;
                }

                // Unset parent and active if side-menu is being hidden
                if (this.menu.show === true) {
                    this.menu.parent = this.menu.active = type;
                } else {
                    this.menu.parent = this.menu.active = '';
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#sidenav {
    width: auto;
    font-family: 'Work Sans';
    visibility: hidden;
    display: flex;
}

.deviations {
    height: 100%;
    .vue-affix {
        width: 195px;
    }
}
.legacy-info-text {
    text-transform: lowercase;
}
</style>
